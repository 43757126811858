.flex-spacer {
  flex: 1 0 auto;
}

.spacer {
  height: 5em;
}

.turbolinks-progress-bar {
  position: relative;
  height: 5px;
  background-color: #00918f;
  z-index: 999;
}

.d-flex {
  display: flex;
}

.align-items-center{ align-items: center; }
.justify-content-center { justify-content: center; }

.bg-main{
  position: relative;
  background-image: url(/assets/images/bg-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  //height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .5);
    z-index: 1;
  }
}

.header-main {
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  //background: #0d6aad;
  padding: 20px;
  margin-top: 20px;
  z-index: 2;
  transition: all 200ms linear;
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  &.sticky {
    position: fixed;
    top: 0;
    margin-top: 0;
    background-color: rgba(#000, .5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    //width: 100%
  }


  .logo-site {
    color: #fff;
    font-weight: bold;
  }

  .menu-header-mobile{
    display: none;

    .button-menu {
      background: none;
      color: #fff;
      border-radius: 4px;
      border: 1px solid #fff;
    }

    .menu-float {
      display: flex;
      //height: 0;
      flex-direction: column;
      .item {
        padding: 10px;
      }
    }
  }

  .menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 40px;
    .item {
      position: relative;
      font-family: 'Alegreya Sans';
      font-size: 16px;
      color: #fff;
      margin: 0 10px;
      padding: 10px;
      border-radius: 6px;
      transition: all 200ms ease-in-out;
      text-transform: uppercase;
      cursor: pointer;
      .title {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;
        .fa {
          height: 20px;
        }
      }
      &:hover {
        background: rgba(#fff, .1);
      }

      &.outline {
        border: 1px solid #fff;
        height: 36px;
        display: flex;
        align-items: center;
        &:hover {
          //background: #fff;
        }
      }

      .fa {
        margin-left: 5px;
      }

      .subitems {
        position: absolute;
        margin-top: 20px;
        background-color: #fff;
        //display: flex;
        min-width: 200px;
        display: none;
        flex-direction: column;
        .subitem {
          display: block;
          color: #000;
          padding: 10px 10px;
          text-align: center;
          border-bottom: 1px solid rgba(#000, .1);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

$margin-top-bottom-titles: 350px;
.title {
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  margin-top: $margin-top-bottom-titles;
}
.subtitle-main {
  text-align: justify;
  font-size: 30px;
  //margin-bottom: $margin-top-bottom-titles;
}

.img-main {
  width: 100%;
  margin-top: 150px;
}
@media (max-width: 768px) {
  .container-header {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .header-main {
    .menu-header-mobile{
      .button-menu{
      }
    }

    .logo-site{
    }
    .menu-header {
      display: none;
    }
    .menu-header-mobile{

      display: block;
      .menu-float {
        position: absolute;
        margin: 20px 16px;
        background: rgba(#fff, .2);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        border-radius: 6px;
        left: 0;
        right: 0;
        .item:not(.no-effects) {
          font-weight: bold;
          text-transform: uppercase;
          color: #fff;
          padding: 20px;
          border-bottom: 1px solid rgba(#ccc, .7);
          transition: all 200ms ease-in-out;

          &.with-items{
            display: flex;
            flex-direction: column;
            .open-menu {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              cursor: pointer;
              width: 34px;
              height: 34px;
              border-radius: 50%;
              background: rgba(#fff, .0);
              transition: all 200ms ease-in-out;
              .fa {
                margin-bottom: 5px;
              }
              &:hover{
                background: rgba(#fff, .2);
              }
            }

            .subitems {
              display: none;
              &.visible {
                display: flex !important;
              }
              width: 100%;
              flex-direction: column;
              .subitem {
                display: flex;
                padding: 10px;
                color: #fff;
                align-items: center;
                .fas {
                  font-size: 5px;
                  margin-right: 10px;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
            }

          }
          //&:hover {
          //  background: rgba(#fff, .4);
          //}
          //&:active {
          //  background: rgba(#ccc, .4);
          //}
        }
      }
    }
  }
}

.box {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid rgba(#000, .1);
  box-shadow: 0 0 3px 1px rgba(#000, .1);

  .icone-box {
    text-align: center;
    i {
      font-size: 50px;
    }
  }

  .title-box {
    font-family: 'Alegreya Sans';
    font-size: 30px;
    text-align: center;
    font-weight: bold;

  }

  p {
    text-align: justify;
  }
}

.main-footer-master {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  background-color: #dbe1e580;

  .title-footer-site {
    font-weight: bold;
    text-transform: uppercase;
    color: #575757;
  }

  .item {
    margin: 0 10px;
  }

  .links {
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }

  .copytext {
    font-size: 9px;
  }

  .content-flex {
    display: flex;
  }
  @media screen and (max-width: 780px) {
    .content-flex {
      flex-direction: column;
    }
    .item{
      margin: 10px 10px;
    }
  }
}


//=================================================================

